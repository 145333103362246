import React, { useState } from "react";
import { createAppContainer } from "react-navigation";
import { createStackNavigator } from "react-navigation-stack";
import { createDrawerNavigator } from "react-navigation-drawer";
import { AppLoading } from "expo";
import * as Font from "expo-font";

import Test from "./src/screens/Test";
import Controller from "./src/screens/Controller";

const DrawerNavigation = createDrawerNavigator(
    { 
      Controller: Controller,
      Test: Test,
    }
  );

const StackNavigation = createStackNavigator(
  {
    // DrawerNavigation: {
    //   screen: DrawerNavigation
    // },
    Controller: Controller,
    // Test: Test,
  },
  {
    headerMode: "none"
  }
);

const AppContainer = createAppContainer(StackNavigation);

// disable console messages in prod
// if(!__DEV__) { console.log = () => {}; }

function App() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  if (!isLoadingComplete) {
    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return isLoadingComplete ? <AppContainer /> : <AppLoading />;
  }
}
async function loadResourcesAsync() {
  await Promise.all([
    Font.loadAsync({
      "roboto-regular": require("./src/assets/fonts/roboto-regular.ttf"),
      "amatic-sc-700": require("./src/assets/fonts/amatic-sc-700.ttf")
    })
  ]);
}
function handleLoadingError(error) {
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}

export default App;
