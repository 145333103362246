import React, { Component } from "react";
import { StyleSheet, Image, View } from "react-native";

// Use an image coin, since SVG ellipse, 
// circle doesn't resize and align properly
function ImageCoin(props) {

  // Remember: require doesn't allow using dynamic names
  return (
    <View style={[styles.container, 
      props.style, 
      props.coinColor==="green" ? styles.greenShadows : styles.redShadows
    ]}>
      <Image
        source={
          (props.coinColor==="green") ? 
          require("../assets/images/green_coin.png") : 
          require("../assets/images/red_coin.png")
        }
        resizeMode="contain"
        style={styles.image}
      ></Image>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    // shadowColor: 'black',
    shadowRadius: 2,
    shadowOpacity: 0.4,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 2,
    borderRadius: 50,
    margin: 5
  },
  image: {
    width: 26,
    height: 26,
    alignSelf: "center",
    alignItems: 'center',
    justifyContent: 'center',

    borderRadius: 50,
    padding: 15,
  },
  greenShadows: {
    shadowColor: "rgba(0,184,148,1)",
  },
  redShadows: {
    shadowColor: "rgba(214,48,49,1)",
  },
});

export default ImageCoin;
