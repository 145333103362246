import React, { Component, useEffect, useState } from "react";
import { StyleSheet, View, KeyboardAvoidingView,
  TextInput, Text, Platform, } from "react-native";

import BasicButton from '../components/BasicButton';
import RoleIconButton from "../components/RoleButton";

import * as Animatable from 'react-native-animatable';
import TransitionView from '../components/TransitionView';

function Home(props) {
  const waitingText = { 
    userA: "Waiting for player(s) to join. \nGame Code : " + props.roomID.toUpperCase(),
    userV: "The game hasn't started yet. Please wait."
  }

  return (
    <TransitionView style={styles.container} >
      <KeyboardAvoidingView
      behavior={Platform.OS == 'ios' ? 'padding' : 'height'}
      style={styles.container}>

      <TransitionView style={styles.logo} animation="bounceInDown">
        <View style={styles.rect2Stack}>
          <Animatable.View 
            animation={props.isWaiting ? "rotate" : ''}
            easing='linear'
            iterationCount="infinite" 
            duration={8000} 
            style={styles.rect2}>
          </Animatable.View>

          <Animatable.View 
            animation={props.isWaiting ? "rotate" : ''}
            easing='linear'
            direction="reverse"
            iterationCount="infinite" 
            duration={8000}
            style={styles.rect}>
          </Animatable.View>

          <Text style={styles.diffCons}>DiffCons</Text>
        </View>
      </TransitionView>

      <TransitionView delay={1000}>
      { !props.isWaiting &&
        <TextInput
          placeholder= { props.joinPressed ? "Enter Game Code" : " Play as - Username " }
          autoCapitalize={"characters"}
          maxLength={10}
          autoFocus={true}
          clearButtonMode="while-editing"
          // keyboardAppearance="default"
          style= {  [styles.usernameInput, props.joinPressed && styles.roomInput] } 
          onChangeText={ (text) => props.onChangeText(text, props.joinPressed) }
          value={ props.joinPressed ? props.roomIDinput : props.username }
        ></TextInput>
      }

      { !props.isWaiting &&
        <View style={ styles.roleGroup }>

          <Text style={styles.rolelabel}>{"Choose your role"}</Text>

          <RoleIconButton 
            icon="user"
            iconStyle={ styles.roleIconA }
            style = {[props.role === "userA" && styles.roleSelected,
              props.role === "userA" && styles.roleHighlightA]}
            onPress={ () => props.onPressRole("userA") }
          ></RoleIconButton>

          <RoleIconButton 
            icon="user"
            iconStyle={ styles.roleIconB }
            style = {[props.role === "userB" && styles.roleSelected,
              props.role === "userB" && styles.roleHighlightB]}
            onPress={ () => props.onPressRole("userB") }
          ></RoleIconButton>        

          <RoleIconButton
            icon="eye" 
            iconStyle={ styles.roleIconV }
            style = {[props.role === "userV" && styles.roleSelected,
              props.role === "userV" && styles.roleHighlightV]}
            onPress={ () => props.onPressRole("userV") }
          ></RoleIconButton>

        </View>
      }

      {/* Show text to userA or userV when userB hasn't joined */}
      { props.isWaiting &&
        <Text style={styles.waitingText}>
          { waitingText[props.role] }
        </Text>
      }     

        <View style={styles.group2}>
          { (props.joinPressed || props.isWaiting) &&
          <BasicButton 
            label="Cancel"
            style={ styles.joinPressedButtons } 
            onPress={ props.handleCancel }
          ></BasicButton>
          }

          { !props.isWaiting &&
          <BasicButton 
            label="Join" 
            style={ props.joinPressed ? styles.joinPressedButtons : styles.basicButton } 
            // disabled={props.isDisabled}
            onPress={ props.joinPressed ? props.joinRoom : props.onPressJoinRoom }
          ></BasicButton>
          }
        </View>
        </TransitionView>
        </KeyboardAvoidingView>
    </TransitionView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  group2: {
    width: 339,
    height: 56,
    flexDirection: "row",
    justifyContent: "space-around",
    marginTop: 20,
    alignSelf: "center"
  },
  roleGroup: {
    width: 339,
    height: 56,
    flexDirection: "row",
    // justifyContent: "space-between",
    justifyContent: "space-around",
    marginTop: 20,
    alignSelf: "center",
    alignItems: 'center'
  },
  rolelabel: {
    width: 120,
    fontFamily: "roboto-regular",
    // color: "#ced6e0",
    color: "#747d8c",
    fontSize: 16,
    textAlign: "center",
  },
  roleIconA: {
    color: "rgba(255,99,72,1)",
  },
  roleIconB: {
    color: "#2ed573",
  },
  roleIconV: {
    color: "rgba(255,165,2,1)",
  },
  roleSelected: {
    backgroundColor: "#F2F2F2",
    transform: [{ scale: 1.4 }],
    // shadowColor: "rgba(255,165,2,1)",
    // shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    borderRadius: 5,
  },
  roleHighlightA: {
    shadowColor: "rgba(255,99,72,1)",
  },
  roleHighlightB: {
    shadowColor: "#2ed573",
  },
  roleHighlightV: {
    shadowColor: "rgba(255,165,2,1)",
  },
  usernameInput: {
    height: 54,
    width: 339,
    textAlign: "center",
    fontSize: 20,
    // borderWidth: 1,
    borderColor: "rgba(255,165,2,1)",
    borderStyle: "solid",
    borderBottomWidth: 3,
    borderRightWidth: 0,
    borderTopWidth: 0,
    borderLeftWidth: 0,
    color: "rgba(44,44,60,1)",
    marginTop: 20,
    alignSelf: "center"
  },
  roomInput: {
    borderColor: "rgba(255,99,72,1)"
  },
  logo: {
    width: 293,
    height: 276,
    marginTop: 50,
    alignSelf: "center"
  },
  rect2: {
    top: 0,
    left: 83,
    width: 145,
    height: 145,
    position: "absolute",
    borderWidth: 20,
    borderColor: "rgba(255,165,2,1)",
    borderStyle: "solid",
    // backgroundColor: "rgba(255,255,255,1)"
  },
  rect: {
    top: 72,
    left: 0,
    width: 146,
    height: 146,
    position: "absolute",
    borderWidth: 10,
    borderColor: "rgba(255,99,72,1)",
    borderRadius: 0.1,
    borderStyle: "dashed"
  },
  diffCons: {
    top: 141,
    left: 146,
    position: "absolute",
    fontFamily: "amatic-sc-700",
    color: "rgba(255,127,80,1)",
    fontSize: 55,
    width: 147,
    height: 69
  },
  rect2Stack: {
    width: 293,
    height: 217,
    marginTop: 26
  },
  joinPressedButtons: {
    // marginTop: 95,
    backgroundColor: "rgba(255,99,72,1)",
  },

  waitingText: {
    // position: "absolute",
    width: 339,
    fontFamily: "roboto-regular",
    fontSize: 16,
    // color: "rgba(44,44,60,1)",
    color: "rgba(255,165,2,1)",
    marginTop: 40,
    textAlign: "center",
    alignSelf: "center"
  },

});

export default Home;
