import React, { useState } from 'react';
import { View, Modal, StyleSheet, Text, TouchableOpacity} from 'react-native';

/*

Usage: 
<GameModal visible={true} 
  onRequestClose={() => this.anyFunction()} 
  title = {} 
  message = {}
  okTitle = {}
  cancelTitle = {} // This will also add the second button, else only 1
  onOk = { () => anyFunction() }   
  onCancel = { () => anyFunction() }    
>
    <View>
       // create your own view here!
    </View>

</GameModal>

*/

function GameModal(props) {
  
  return (
    <Modal
      animationType="slide"
      transparent={false}
      visible={props.visible}
      // style={styles.modalView}
      onRequestClose={props.onRequestClose}>

        <View style={styles.modal}>
          <View>
            <View style={styles.modalContainer}>
      
              <View style={styles.modalHeader}>
                <Text style={styles.title}>{props.title}</Text>
                <View style={styles.divider}></View>
              </View>

              <View style={styles.modalBody}>
                <Text style={styles.bodyText}>{props.message}</Text>
                {this.props.children}
              </View>  

              <View style={styles.modalFooter}>
                <View style={styles.divider}></View>
                <View style={{flexDirection:"row-reverse",margin:10}}>
                {
                  props.cancelTitle &&
                    <TouchableOpacity style={styles.actions} 
                      onPress={props.onCancel}
                      // onPress={() => {
                      //   setModalVisible(!modalVisible);
                      // }}
                    >
                      <Text style={styles.actionText}>{ props.cancelTitle || "Cancel" }</Text>
                    </TouchableOpacity>
                }

                  <TouchableOpacity style={styles.actions}
                    onPress={props.onOk}
                  >
                    <Text style={styles.actionText}>{ props.okTitle || "Ok" }</Text>
                  </TouchableOpacity>

                </View>
              </View>

            </View>
          </View>
        </View>
    </Modal>

  );
}

const styles = StyleSheet.create({
  
  modal:{
    backgroundColor:"#00000099",
    flex:1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContainer:{
    // backgroundColor:"#f9fafb",
    // backgroundColor:"#fff",
    backgroundColor:"#F2F2F2",
    // width:"80%",
    borderRadius:5
  },
  modalHeader:{
    alignItems: 'center',
  },
  title:{
    fontFamily: "roboto-regular",
    fontWeight:"bold",
    fontSize:20,
    padding:15,
    // color:"#000"
    color: "rgba(44,58,71,1)",
  },
  divider:{
    width:"100%",
    height:1,
    backgroundColor:"lightgray"
  },
  modalBody:{
    // backgroundColor:"#fff",
    // backgroundColor:"#f9fafb",
    // backgroundColor:"#F2F2F2",
    paddingVertical:10,
    paddingHorizontal:10,
    justifyContent: "center",
    alignItems: "center"
  },
  modalFooter:{
    
  },
  actions:{
    // borderRadius:5,
    marginHorizontal:10,
    paddingVertical:10,
    paddingHorizontal:20,

    width: 160,
    flex: 1,
    borderRadius: 5,
    backgroundColor: "rgba(255,99,72,1)"
  },
  actionText:{
    // color:"#fff",
    textAlign: "center",
    // fontSize: 20,
    fontFamily: "roboto-regular",
    color: "rgba(255,255,255,1)",
  }
});

export default GameModal