import React, { Component, useState, useEffect } from 'react';
import { StyleSheet, View,} from 'react-native';

import shortid  from 'shortid';
import fireDB from '../firebaseConfig'

import Home from './Home';
import Game from './GameNoAnim';
// import Game from './GameWithFlip';
import CustomModal from '../components/CustomModal'

function Controller(props) {

  const[roomID, setroomID] = useState('')
  const[roomIDinput, setroomIDinput] = useState('')

  const[username, setusername] = useState('') // Username entered by user on current device
  const[userRole, setuserRole] = useState('') // Userrole entered by user on current device

  const[isActiveUser, setisActiveUser] = useState(true) // True when the user can do actions
  const[isWaiting, setisWaiting] = useState(false) // True when the room creator waits for an opponent
  const[isPlaying, setisPlaying] = useState(false) // True when the opponent joins a room channel
  const[userPlaying, setuserPlaying] = useState(false) // Local state of the user
  const[isDisabled, setisDisabled] = useState(false) // True when 'Create' button is pressed

  const[userA, setuserA] = useState('')
  const[userB, setuserB] = useState('')
  const[viewers, setviewers] = useState([])

  const [joinPressed, setJoinPressed] = useState(false) // True when non-active players joins (local only)

  const maxOccupancy = 5
  var matchID = 1

  let today = new Date().toISOString().slice(0, 10)
  const roomsPrefix = 'games/' + today + '/'
  const matchPrefix = '/match--' + matchID
  let firebaseRef = null

  // Equivalent to componentDidMount and componentWillUnmount
  useEffect(() => {
    // console.log("Co: effect")

    firebaseRef = fireDB.ref(roomsPrefix + roomID + matchPrefix);
    console.log("Co: Data listener ON ", roomID, matchID )
    // console.log("for ", firebaseRef.toString())
    
    const controllerListener = firebaseRef.on('value', snapshot => {
      if(snapshot.exists()) {
        const data = snapshot.val();
        console.log("Co: fb ", data)

        // Check if DB data has some values, and isWaiting is false 
        // Update local state based on firedb
        // sync match data
        
        if(data.userA !== userA) { 
          console.log("Syncing a", data.userA, userA)
          setuserA(data.userA) 
        }
        if(data.userB !== userB) { 
          console.log("Syncing b", data.userB, userB)
          setuserB(data.userB) 
        }
        if(data.viewers) {
          if(JSON.stringify(data.viewers) !== JSON.stringify(viewers)) { 
            console.log("Syncing v", data.viewers, viewers)
            setviewers(data.viewers) 
          }
        }
        else {
          setviewers([]) 
        }

        if(data.isWaiting !== isWaiting) {
          console.log("Syncing waiting", data.isWaiting, isWaiting) 
          setisWaiting(data.isWaiting) 
        }

        // sync room data
        if(data.isPlaying !== isPlaying) { 
          console.log("Syncing playing", data.isPlaying, isPlaying)
          setisPlaying(data.isPlaying) 
        }

        if(data.gameOver) {
          resetAllStates()
        }
      }
    });

    // Return function for cleanup 
    return () => {
      console.log("Co: Data listener OFF")
      firebaseRef.off('value', controllerListener)
    };
  }, [roomID, isPlaying, userA, userB]);
  
  // function printCurrentStates() {
  //   const tempAllStates = { roomID: roomID, roomIDinput: roomIDinput, 
  //     username: username, userRole: userRole, isActiveUser: isActiveUser, 
  //     isWaiting: isWaiting, isPlaying: isPlaying, userPlaying: userPlaying, 
  //     isDisabled: isDisabled, userA: userA, userB: userB, 
  //     viewers: viewers, joinPressed: joinPressed }

  //   console.log("Current states: ",  tempAllStates)
  // }

  // // Generate a unique user ID
  // function getUniqueID(name) {
  //   return name + '_' + shortid.generate()
  // }
  
  // Don't set the roomID directly, else for an existing 
  // room the game screen loads directly without pressing 
  // join button, since in DB isPlaying for the room is true
  function onChangeText(text, joinPressed) {
    text = text.toUpperCase()
    joinPressed ? setroomIDinput(text) : setusername(text) 
  }

  function handleCancel(){
    // TODO : check what happens if user A created, viewer joined, user A canceled : BUG
    // TODO : check what happens if user A created, user A canceled, user B tries to join : WORKS

    // if userA, clear off the game in db before clearing up states
    if(userRole === "userA") {
      fireDB.ref(roomsPrefix + roomID + matchPrefix).set(null)
    }

    setJoinPressed(false)
    setuserRole(null)
    setisWaiting(false)
  }

  function resetAllStates() {
    // Don't reset username, let it persist
    setroomID('')
    setroomIDinput('')
    setuserRole('')
    setisActiveUser(true)
    setisWaiting(false)
    setisPlaying(false)
    setuserPlaying(false)
    setisDisabled(false)

    setuserA('')
    setuserB('')
    setviewers([])

    setJoinPressed(false)
  }

  function onPressJoinRoom() {
    if(username.trim() === ''){
      // Alert.alert('Error', 'Please enter a `username')
      alert('\nPlease enter a username')
    }
    else{
      // console.log("ROLE: ", userRole)

      if(userRole === "userA"){ 
        initiateRoom()
      }
      else if(userRole === "userB" || userRole === "userV") {
        setJoinPressed(true) 
      }
      else {
        alert('\nPlease choose your role')
      }
    }
  }

  function randomRoomID() {
    return shortid.generate().substring(0,5).toUpperCase();
  }

  function getCurrentOccupancy(data) {
    let participants = 0
    if(data.userA) { participants++ }
    if(data.userB) { participants++ }
    if(data.viewers) { participants = participants + data.viewers.length }

    return participants
  }

  function initiateRoom() {
    const roomShortID = randomRoomID()

    setroomID(roomShortID)
    setuserA(username)
    setuserPlaying(true)
    setisWaiting(true)
    setisDisabled(true)

    firebaseRef = fireDB.ref(roomsPrefix + roomShortID + matchPrefix)
    firebaseRef.set(
      {
        userA: username,
        isWaiting: true,
      }
    )

    // TODO : show the Spinner component while waiting for someone to join the room : DONE
  }

  function joinRoom() {
    if(roomIDinput.trim() === ''){
      // Alert.alert('Error', 'Please enter a `username')
      alert('\nPlease enter a game code')
    }
    else {
      firebaseRef = fireDB.ref(roomsPrefix + roomIDinput + matchPrefix)
      firebaseRef.once('value')
      .then( snapshot => {
        // Checks if the room exists and is a valid room
        if (snapshot.exists()) {
          console.log("Valid code:" + roomIDinput)

          const roomData = snapshot.val();
          // console.log(roomData)
          if(roomData.gameOver) {
            alert("\nThat game is long over. \nTry a newer game code.")
            return;
          }

          const participants = getCurrentOccupancy(roomData)

          // Check if the room is full
          if(participants === maxOccupancy) {
            alert("\nThe game is full. \nPlease try another game code")
            // Stop any further execution
            return 
          }

          if(userRole === "userB") {
            // Join as playerB

            firebaseRef.update(
              {
                userB: username,
                isWaiting: false,
                isPlaying: true,
              })
            
            setuserB(username)
            setisActiveUser(false)
          }
          else if(userRole === "userA") {
            firebaseRef.update(
              {
                userA: username,
                isWaiting: false,
                isPlaying: true,
              })
            
            setuserA(username)
            setisActiveUser(true)
          }
          else if(userRole === "userV"){
            // player is a viewer. Join as viewer
            const existingViewers = roomData.viewers ? roomData.viewers : viewers
            const tempViewers = [...existingViewers, username ]
            // console.log("Viewers: ", tempViewers)

            firebaseRef.update(
              {
                viewers: tempViewers,
              }
            )

            setviewers(tempViewers)
            setisWaiting(true)
            setisDisabled(true)
            setisActiveUser(false)
          }
          // TODO : BUG : If user chooses role A here, he gets this too
          // TODO : Gap : UserA cannot join an existing room if dropped off
          else {
            alert('\nPlease choose your role')
          }
    
          setuserPlaying(true)
          setroomID(roomIDinput)
        }
        else {
          alert("\nThat game code looks made up. \nPlease give me a valid game code.")
          return
        }
      })
    }
  }

  function selectRole(role) {
    let tempRole = null

    // To handle user re-clicking / changing role button in UI
    if(userRole !== role){
      tempRole = role
    }
    else {
      tempRole = userRole ? null : role
    }

    console.log("Role chosen: " + tempRole)
    setuserRole(tempRole)
  }

  // TODO : bug : If a user's role is replaced by another joining,
  // both users retain the same role (say green)
  function removeUser(firebaseRef, role) {
    if(role === "userA") {
      firebaseRef.update( { userA: ''} )
      setuserA('')
    }
    else if(role === "userB") {
      firebaseRef.update( { userB: ''} )
      setuserB('')
    }
    else if(role === "userV") {
      const tempViewers = viewers.filter(name => name !== username) 

      // console.log("temp viewers", tempViewers)
      firebaseRef.update( { viewers: tempViewers } )
      setviewers(tempViewers)
    }
    else {
      console.log("Invalid role: ", role)
    }
  }

  return (
    <View style={styles.container}>

      {
        (!isPlaying || (isPlaying && !userPlaying)) &&
        // !isPlaying &&
        <Home 
          roomIDinput = { roomIDinput }
          roomID = { roomID }
          username = { username } 
          onChangeText = { onChangeText }
          handleCancel = { handleCancel }
          joinRoom = { joinRoom }
          onPressJoinRoom = { onPressJoinRoom }
          isDisabled={ isDisabled }
          isWaiting = { isWaiting }
          onPressRole = { selectRole }
          role = { userRole }
          joinPressed = { joinPressed }
        />
      }

      {
        isPlaying && userPlaying &&
        // isPlaying &&
        <Game 
          roomID={roomID}
          username={username} 
          userA={userA}
          userB={userB}
          isActiveUser={isActiveUser}
          viewers = { viewers }
          firebaseRef={fireDB.ref(roomsPrefix + roomID + matchPrefix)}
          role = { userRole }
          removeUser = { removeUser }
          resetAllStates = {resetAllStates}
          // {...props}
        />
      }
    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});

export default Controller;
