// import firebase from 'firebase'
import firebase from 'firebase/app';
import 'firebase/database';

// var firebaseConfig = {
//   apiKey: "AIzaSyB7xjyIozzzdBlSDjLNpR1DoCqYg7esOEk",
//   authDomain: "difftest-8f887.firebaseapp.com",
//   databaseURL: "https://difftest-8f887.firebaseio.com",
//   projectId: "difftest-8f887",
//   storageBucket: "difftest-8f887.appspot.com",
//   messagingSenderId: "590606247478",
//   appId: "1:590606247478:web:85e602561f05edc31c9a97",
//   measurementId: "G-P5S5Q72SC0"
// };

const firebaseConfig = {
  apiKey: "AIzaSyC-32vI88V4Lg_r4DlaJjAe1ot_PN_Dx6Q",
  authDomain: "koen-diffcons.firebaseapp.com",
  databaseURL: "https://koen-diffcons.firebaseio.com",
  projectId: "koen-diffcons",
  storageBucket: "koen-diffcons.appspot.com",
  messagingSenderId: "362014640708",
  appId: "1:362014640708:web:6918956b03ad4ea4a1ca11",
  measurementId: "G-XWLHZLXDJG"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const fireDB = firebaseApp.database();

export default fireDB;