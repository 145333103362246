import React, { Component } from "react";
import { StyleSheet, View, TouchableOpacity, Text } from "react-native";

function BasicButton(props) {
  return (
    <View style={[styles.container]}>
      <TouchableOpacity 
        style={[styles.button, props.style, props.disabled && styles.buttonDisabled]} 
        onPress={props.onPress} disabled={props.disabled}>
        <Text style={styles.label}>{props.label || "BUTTON"}</Text>
      </TouchableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    height: 56,
    // width: 340,
    // flex: 1
  },
  button: {
    backgroundColor: "rgba(255,165,2,1)",
    borderRadius: 10,
    justifyContent: "center",
    // height: 56,
    width: 160,
    flex: 1
  },

  buttonDisabled: {
    opacity: 0.5
  },
  label: {
    fontFamily: "roboto-regular",
    color: "rgba(255,255,255,1)",
    fontSize: 20,
    textAlign: "center"
  }
});

export default BasicButton;
