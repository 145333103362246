import React, { Component } from "react";
import { StyleSheet, TouchableOpacity, Text, } from "react-native";
import { Ionicons } from '@expo/vector-icons'

function IconButton(props) {
  return (
    <TouchableOpacity style={[styles.container, props.style]} onPress={props.onPress}>
      <Ionicons name={props.icon || "ios-arrow-back"} style={[styles.icon, props.iconStyle]}></Ionicons>
      <Text style={styles.label}>{props.label || ""}</Text>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  icon: {
    color: "rgba(44,58,71,1)",
    fontSize: 26,
    alignSelf: "center",
    margin: 5,
  },
  label: {
    fontFamily: "roboto-regular",
    color: "rgba(44,58,71,1)",
    fontSize: 18,
    alignSelf: "center",
  },
});

export default IconButton;
