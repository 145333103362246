import React, { Component } from "react";
import { StyleSheet, TouchableOpacity, Image, ImageBackground, Text, Dimensions} from "react-native";

function GameCard(props) {

  // Remember: "require" doesn't allow using dynamic names, only absolute. 
  const cardImage = {  
    "green": require("../assets/images/green_cloud_burst.png"), 
    "red": require("../assets/images/red_cloud_burst.png")
  }

  // TODO:
  // function portraitMode() {
  //   const dimensions = Dimensions.get('screen');
  //   return dimensions.height >= dimensions.width 
  // }
  
  return (
    <TouchableOpacity 
      onPress={props.onPress}
      style={
        [styles.container, 
          // portraitMode() ? styles.containerPortrait : styles.containerLandscape,
          styles.containerPortrait,
          props.style, 
          props.highlight && styles.highlighted,
          props.highlight && props.color && styles.greenShadows,
          props.highlight && !props.color && styles.redShadows
        ]
      }
    >
      <ImageBackground
        source={ props.color ? cardImage["green"] : cardImage["red"] }
        resizeMode="cover"
        style={styles.image}
        imageStyle={styles.imageStyle}
      >
        <Text style={styles.label}>
          {props.label || "Lorem Ipsum"}
        </Text>
      </ImageBackground>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    shadowColor: 'black',
    shadowRadius: 2,
    shadowOpacity: 0.8,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    elevation: 4,
    alignSelf: "center",
  },
  containerLandscape: {
    width: 113,
    height: 297,
  },
  containerPortrait: {
    width: "95%",
    height: "24%",
    minHeight: 70,
    minWidth: 250
  },
  highlighted: {
    transform: [{ scale: 1.15 }],
    margin: 10,
//     shadowRadius: 5,
//     shadowOpacity: 0.8,
//     shadowOffset: {
//       width: 3,
//       height: 3,
//     },
//     elevation: 4,
    
    shadowRadius: 6.5,
    shadowOpacity: 0.5,
    shadowOffset: {
      width: 0,
      height: 5,
    },
    elevation: 10,
  },
  image: {
    borderRadius: 15,
    justifyContent: "center",
    flex: 1,
    overflow: "hidden"
  },
  imageStyle: {},
  label: {
    fontFamily: "roboto-regular",
    color: "#dfe6e9",
    fontSize: 28,
    alignSelf: "center"
  },
  greenShadows: {
    shadowColor: "rgba(0,184,148,1)",
  },
  redShadows: {
    shadowColor: "rgba(214,48,49,1)",
  },
});

export default GameCard;
