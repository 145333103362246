import React, { Component } from "react";
// import { View, StyleSheet, TouchableOpacity, Image, ImageBackground, Text, Dimensions} from "react-native";

import * as Animatable from 'react-native-animatable';

function TransitionView(props) {
  
  return (
    <Animatable.View
      animation= "fadeIn"
      duration={3000}
      // delay={index ? (index * theme.transitionDuration) / 5 : 0}
      useNativeDriver
      {...props}
    />
  );
}

// const styles = StyleSheet.create({
//   container: {},
// });

export default TransitionView;