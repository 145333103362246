import React, { Component, useRef } from "react";
import { StyleSheet, TouchableOpacity, View, Text, ImageBackground} from "react-native";

const Test = (props) => {

return (
    <View style={styles.container}>
      
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F5FCFF',
  },
  
});

export default Test;
