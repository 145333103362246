import React, { Component } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
// import { FeatherIcon } from "@expo/vector-icons";
import { Feather } from '@expo/vector-icons'; 


function RoleIconButton(props) {
  return (
    <TouchableOpacity style={[styles.container, props.style]} onPress={props.onPress}>
      <Feather name={props.icon} style={[styles.icon, props.iconStyle]}></Feather>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center"
    // backgroundColor: "#F2F2F2"
  },
  icon: {
    color: "rgba(44,58,71,1)",
    fontSize: 36,
    alignSelf: "center"
  }
});

export default RoleIconButton;
