import React, {Component, useState, useEffect, useRef} from "react";
import { StyleSheet, View, TouchableOpacity, Image, Text, Platform, Alert } from "react-native";
import { Feather } from '@expo/vector-icons'; 
import { Audio } from 'expo-av';

import IconButton from "../components/IconButton";
import ImageCoin from "../components/ImageCoin";
import GameCard from "../components/GameCard";

import TransitionView from '../components/TransitionView';

function Game(props) {

  const[coinsA, setcoinsA] = useState({red: 5, green: 5})
  const[coinsB, setcoinsB] = useState({red: 0, green: 0})
  const[activeCoin, setactiveCoin] = useState('')

  const[cardColor, setcardColor] = useState({card1: false, card2: false, card3: false})
  const[cardHighlight, setcardHighlight] = useState('')
  
  const[matchOver, setmatchOver] = useState(false)

  const coinElements = { 
    playerA: {red: [], green: []}, 
    playerB: {red: [], green: []}
  }

  const soundObject = new Audio.Sound();
  const sounds = {
    "highlight": require("../assets/sounds/coin-effect.mp3"),
    "flip": require("../assets/sounds/card-flip.mp3"),
  }

  useEffect(() => {
    // console.log("Ga: effect")

    checkMatchOver()

    // TODO : Bug : Due to syncing for active user too, highlight etc run multiple times
    // like 2-3 times. Fix it. 

    // TODO : Bug : When users exits, it'll kick out other users with the same name too,
    // due to filtering.

    // if(!props.isActiveUser) {
      console.log("Ga: Data listener ON ") 
      // console.log("for ", props.firebaseRef.toString())

      const listener = props.firebaseRef.on('value', snapshot => {
        if(snapshot.exists()){
          let data = snapshot.val();
          console.log("Ga: fb ", data)

          // Sync pressed coin
          if(typeof(data.activeCoin) !== 'undefined' && data.activeCoin !== activeCoin) { 
            console.log("Syncing active coin")
            // console.log("DB has: ", data.activeCoin)
            // console.log("Local has: ", activeCoin)

            handleCoinPress(data.activeCoin)
          }

          // Sync coins count
          if(data.coinsA && (data.coinsA.green !== coinsA.green || data.coinsA.red !== coinsA.red)) { 
            console.log("Syncing coins")
            // console.log("DB has: ", data.coinsA)
            // console.log("Local has: ", coinsA)

            setcoinsA(data.coinsA)
            setcoinsB(data.coinsB) 
          }
          
          // Sync card color state
          if(data.cardColor) {
            // Find the diff, since useEffect dependencies do a shallow compare. key will be cardID
            let colorDiff = Object.keys(cardColor).filter(key => cardColor[key] !== data.cardColor[key])
            
            colorDiff.forEach(key => {
              console.log("Syncing card color for : " + key)
              flipCard(key)
              // setcardColor({ ...cardColor, [key]: data.cardColor[key]})
            })
          } 

          // Sync card highlight
          if(data.cardHighlight && data.cardHighlight != cardHighlight) {
            console.log("Syncing card highlight for : " + data.cardHighlight)
            highlightCard(data.cardHighlight) 
          }

        }
      }) // End listener

      // Return function for cleanup 
      return () => {
        console.log("Ga : Data listener OFF")
        props.firebaseRef.off('value', listener)
      };
    // }
  // }, [])
  },[props.isActiveUser, activeCoin,
    coinsA.red, coinsA.green, coinsB.red, coinsB.green,  
    cardColor.card1, cardColor.card2, cardColor.card3,
    // NOT NEEDED, as they re-call the effect unnecessarily 
    // props.firebaseRef, cardHighlight
  ])

  // TODO : Fix this for multiple sound plays
  function playSound(effect) {
    // Audio.setIsEnabledAsync(true);
    // const sound = new Audio.Sound();

    const playLocalSound = (async () => {
      // if (Platform.OS === "ios") {
      //   await Audio.setAudioModeAsync({
      //     playsInSilentModeIOS: true,
      //   })
      // }
      try {
        const status = await soundObject.getStatusAsync();
        // console.log("Sound status", status)

        if(status.isLoaded == false){
          await soundObject.loadAsync(sounds[effect])
        }
        await soundObject.playAsync()

        // unload the sound from memory
        // await soundObject.unloadAsync();
      }
      catch(error) { 
        console.log(error)
      }

    })()
  }

  // Generate coin elements dynamically and add them to view
  // Also each time coins count changes
  function generateCoins(player, color, count) {
    // console.log("Generating coins")

    for (let i = 1; i <= count; i++) {
      // Scale only the last coin
      if(player==="playerA" && activeCoin===color && i===count){
        coinElements[player][color].push(<ImageCoin coinColor={color} style={styles.coinPressed}
          strokeWidth = {1.5} key={`${color}Coin-${i}`}></ImageCoin>)
        continue;
      }
    
      coinElements[player][color].push(<ImageCoin coinColor={color} style={styles.coins}
        key={`${color}Coin-${i}`}></ImageCoin>)
    }
  }

  // Will only be called by playerA since only he can press coins 
  function handleCoinPress(coinColor){
    // Use a temp state, since setState doesn't apply immediately
    // hence can't be passed to firebase immediately
    const tempCoinState = activeCoin ? '' : coinColor
    setactiveCoin(tempCoinState)
    // playSound("coinPress")

    if(props.isActiveUser) {
      props.firebaseRef.update(
        {
          activeCoin: tempCoinState
        }
      )
    }
  }

  // Will only be called by playerA since only he can press coins 
  function handleCardPress(cardID) {
    // activeCoin ? highlightCard(cardID) : flipCard(cardID)

    const tempCardColor = cardColor[cardID] ? "green" : "red"

    if(activeCoin){
      if( activeCoin === tempCardColor) {
        highlightCard(cardID)
      }
      else {
        highlightCard(cardID)
        flipCard(cardID)
      }
    }
    else {
      flipCard(cardID)
    }
  }

  // toggle the boolean state
  // green color is true, red color is false
  // Apply Animation
  // Play sound
  // Change card color
  function flipCard(cardName){
    console.log("Flipping: " + cardName);

    const tempCardColor = { ...cardColor, [cardName]: !cardColor[cardName]}
    
    // Only userA should write to DB
    if(props.isActiveUser) {
      props.firebaseRef.update(
        {
          cardColor: tempCardColor,
        }
      )
    }

    setcardColor(tempCardColor)
    playSound("flip")
  }

  // toggle the boolean state
  // green color is true, red color is false
  function highlightCard(cardName) {
    console.log("Highlighting: " + cardName);

    // const tempCardHighlight = { ...cardHighlight, [cardName]: true }

    if(props.isActiveUser) {
      props.firebaseRef.update(
        {
          cardHighlight: cardName,
        }
      )

      // For other users, its already synced in useeffect
      transferCoin()
    }

    setcardHighlight(cardName)
    playSound("highlight")
    removeCardHighlight()
  }

   // Remove the card highlight after 5 secs
  function removeCardHighlight() {
    console.log("Removing highlight")
    const duration = 3000

    setTimeout(()=>{
      // tempCardHighlight = { ...cardHighlight, [cardName]: false }
      props.isActiveUser &&
        props.firebaseRef.update(
          {
            cardHighlight: '',
          }
        )
  
      setcardHighlight('')
    }, duration)
  }

  function transferCoin(){
    const tempCoinsA = { ...coinsA, [activeCoin]: (coinsA[activeCoin] - 1) }
    const tempCoinsB = { ...coinsB, [activeCoin]: (coinsB[activeCoin] + 1) }

    if(props.isActiveUser) {
      props.firebaseRef.update(
        {
          coinsA: tempCoinsA,
          coinsB: tempCoinsB,
          activeCoin: '',
        }
      )
    }
    
    setactiveCoin('')
    setcoinsA(tempCoinsA)
    setcoinsB(tempCoinsB)    
  }

  function checkMatchOver() {
    console.log("Checking match over")
    const coinsExhausted = (coinsA.green===0 && coinsA.red===0);
    // const allCardsGreen = cardColor.card1 && cardColor.card2 && cardColor.card3;

    if(coinsExhausted){
      console.log("MATCH OVER")
      const duration = cardHighlight ? 3500 : 2000
      setmatchOver(true)

      setTimeout(()=>{
        alert("\nGame Over");
        // TODO: Show popup to play again

        // Controller would sync this and exit game screen back to home
        if(props.isActiveUser) {
          props.firebaseRef.update(
            {
              gameOver: true,
              isPlaying: false
            }
          )
        }
      }, duration)
    }
  }

  function checkPressable() {
    return props.isActiveUser && !matchOver ? 'auto' : 'none'
  }

  function handleExit() {
    const message = props.username + ", are you sure you want to exit the game ?"

    if (Platform.OS === "web") {
      // confirm(message)
      let response = confirm(message)

      if(response) {
        console.log(props.role + " " + props.username + " EXITING game")
        props.removeUser(props.firebaseRef, props.role)
        props.resetAllStates()
      }
      else {
        // console.log("Cancel Pressed")
      }
    }
    else {
      Alert.alert(
        "Exit Game",
        message,
        [
          {
            text: "Cancel",
            onPress: () => console.log("Exit cancelled"),
            style: "cancel"
          },
          { text: "OK", 
            onPress: () => {
              console.log(props.role + "user: " + props.username + " EXITING game")
              props.removeUser(props.firebaseRef, props.role)
              props.resetAllStates()
            }
          }
        ],
        { cancelable: false }
      );
  
    }
  }

  function handleShowViewers() {
    // console.log("Viewers: ", props.viewers)
    if(props.viewers && props.viewers.length > 0) {
      alert("\nWe are watching your game: \n\n" + props.viewers.join(" | "))
    }
    else{
      alert("\nNo viewers yet. Invite them to join using code: " + props.roomID.toUpperCase())
    }
  }

  return (
    <TransitionView style={styles.container}>
      {/* {console.log("Reloading game ", props.userA, props.userB, props.viewers)} */}
      <View style={styles.headerColumn}>

        <View style={[styles.header, (Platform.OS === "web" && styles.headerWeb)]}>
          <IconButton 
            style={styles.back}
            label ="Exit"
            // onPress={() => props.navigation.navigate("Home")}
            onPress={ handleExit }
          >
          </IconButton>

          <View style={styles.backFiller}>
            <Text style={styles.matchTitle}>KoeN DiffCons ({ props.roomID.toUpperCase() })</Text>
          </View>
          <IconButton
            icon="ios-eye"
            style={styles.more}
            onPress={ handleShowViewers }
          ></IconButton>
        </View>

        <View style={styles.endWrapperFillerRow} pointerEvents={ checkPressable() }>
          <View style={styles.endWrapperFiller}></View>
          <View style={styles.group3StackRow}>
            <View style={styles.group3Stack}>
              <View style={styles.greenCoinsB}>
                { generateCoins("playerB", "green", coinsB.green) }
                { coinElements.playerB.green }
              </View>
              <View style={styles.redCoinsB}>
                { generateCoins("playerB", "red", coinsB.red) }
                { coinElements.playerB.red }
              </View>
            </View>
            <View style={styles.group5}>
              <View style={styles.stackFiller}></View>
              <View style={styles.userStack}>
                <View style={[styles.userIconContainer, styles.userIconContainerB ]}>
                  <Feather name="user" size={50} style = {styles.userIconB} />
                </View>
                <Text style={styles.playerName}>
                  { props.userB.toUpperCase() }
                </Text>
              </View>
            </View>
          </View>
        </View>
        
      </View>

      <View style={styles.cardsGroup} pointerEvents={ checkPressable() }>
        <GameCard label="SAFETY" 
          color = { cardColor.card1 }
          highlight = { cardHighlight === "card1" }
          onPress = { () => handleCardPress("card1") }
        ></GameCard>

        <GameCard label="RESPECT" 
          color = { cardColor.card2 }
          highlight = { cardHighlight === "card2"  }
          onPress={ () => handleCardPress("card2") }
        ></GameCard>

        <GameCard label="INTEREST" 
          color = { cardColor.card3 }
          highlight = { cardHighlight === "card3"  }
          onPress={ () => handleCardPress("card3")}
        ></GameCard>
      </View>

      <View style={styles.group2Row} pointerEvents={ checkPressable() }>
        <View style={styles.group2}>
          <View style={styles.stackFiller}></View>

          <View style={styles.userStack}>
            <View style={[styles.userIconContainer, styles.userIconContainerA ]}>
              <Feather name="user" size={50} style = {styles.userIconA}/>
            </View>
            <Text style={styles.playerName}>
              { props.userA.toUpperCase() }
            </Text>
          </View>
        </View>

        <View style={styles.endWrapperFillerColumn}>
          <View style={styles.endWrapperFiller}></View>
          <View style={styles.greenCoinsA2Column}>
            <TouchableOpacity 
              onPress={ () => handleCoinPress("green") }
              disabled = {coinsA.green===0}
              style={styles.greenCoinsA} 
            >
              { generateCoins("playerA", "green", coinsA.green) }
              { coinElements.playerA.green }
            </TouchableOpacity>

            <TouchableOpacity 
              onPress={() => handleCoinPress("red") }
              disabled = {coinsA.red===0}
              style={styles.redCoinsA} 
            >
              { generateCoins("playerA", "red", coinsA.red) } 
              { coinElements.playerA.red }
            </TouchableOpacity>
          </View>
        </View>

      </View>
    </TransitionView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  header: {
    backgroundColor: "#F2F2F2",
    shadowColor: "rgba(0,0,0,1)",
    shadowOffset: {
      width: 3,
      height: 3
    },
    elevation: 5,
    shadowOpacity: 0.05,
    shadowRadius: 0,
    height: 70,
    flexDirection: "row"
  },
  headerWeb: {
    height: 50
  },
  back: {
    height: 51,
    // width: 47,
    alignSelf: "flex-end",
    marginLeft: 14
  },
  matchTitle: {
    fontFamily: "roboto-regular",
    color: "rgba(44,58,71,1)",
    fontSize: 18,
    marginBottom: 15
  },
  backFiller: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "center"
  },
  more: {
    height: 51,
    width: 47,
    alignSelf: "flex-end",
    marginRight: 14
  },
  endWrapperFiller: {
    flex: 1
  },
  coins:{
    width: 27,
    height: 27
  },
  greenCoinsB: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-around",
    width: 174,
    height: 43,
    justifyContent: "flex-end",
    // marginBottom: 27
  },
  redCoinsB: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-around",
    width: 174,
    height: 43,
    justifyContent: "flex-end",
  },
  group3Stack: {
    width: 174,
    height: 85,
    marginRight: 5
  },
  group5: {
    width: 82,
    height: 84,
    // marginRight: 10
    alignSelf: "flex-end" 
  },
  stackFiller: {
    flex: 1
  },
  userIconA: {
    color: "#d63031",
  },
  userIconB: {
    color: "#00b894",
  },
  userIconContainer: {
    width: 65,
    height: 65,
    position: "absolute",
    bottom: 15,
    backgroundColor: "#F2F2F2",
    // left: 0,
    top: 0,
    alignItems: "center",
    justifyContent: 'center',

    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 2,
    elevation: 2,
    borderRadius: 10,
  }, 
  userIconContainerA: {
    // borderColor: "#d63031",
    shadowColor: "#d63031",
  },
  userIconContainerB: {
    // borderColor: "#00b894",
    shadowColor: "#00b894",
  },
  // playerA2: {
  //   position: "absolute",
  //   fontFamily: "roboto-regular",
  //   color: "rgba(44,44,60,1)",
  //   bottom: 0,
  //   left: 0,
  //   right: 0,
  //   textAlign: "center",
  // },
  // image6Stack: {
  //   width: 69,
  //   height: 84
  // },
  group3StackRow: {
    height: 85,
    flexDirection: "row"
  },
  endWrapperFillerRow: {
    height: 85,
    flexDirection: "row",
    marginTop: 14,
    // marginLeft: 118,
    marginRight: 14
  },
  headerColumn: {},
  cardsGroup: {
    maxWidth: 318,
    width: "77%",
    justifyContent: "space-around",
    flex: 1,
    // marginBottom: 5,
    // marginTop: 5,
    // marginLeft: 5,
    margin: 5,
    alignSelf: "center",
  },
  group2: {
    width: 82,
    height: 84,
    alignSelf: "flex-end" 
  },
  playerName: {
    position: "absolute",
    fontFamily: "roboto-regular",
    fontSize: 12,
    color: "rgba(44,44,60,1)",
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: "center",
    marginTop: 5,
  },
  userStack: {
    width: 82,
    height: 84,
    alignItems: "center",
  },
  greenCoinsA: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-around",
    width: 174,
    height: 43,
    justifyContent: "flex-start",
    // marginBottom: 27
  },
  redCoinsA: {
    flexDirection: "row",
    alignItems: "center",
    // justifyContent: "space-around",
    width: 174,
    height: 43,
    justifyContent: "flex-start",
  },
  coinPressed: {
    transform: [{ scale: 1.3 }]
  },
  greenCoinsA2Column: {
    width: 174,
    marginLeft: 5
  },
  endWrapperFillerColumn: {
    width: 174,
    marginBottom: 2
  },
  group2Row: {
    height: 88,
    flexDirection: "row",
    marginBottom: 14,
    marginLeft: 14,
    marginRight: 118
  },
});

export default Game;
